/* eslint-disable import/extensions */
// @flow
import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { DYNAMICK_LINK, SITE_METADATA } from '../../constants';
import { EditorPicks, WhereToShop } from '../../components';
import { fetchEditorsPicks } from '../../redux/editors-pick';
import Layout from '../../components/Layout/Layout';
import ShopWithPaidy from '../../components/Shop/ShopWithPaidy';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import styles from '../../styles/pages/shop.module.scss';

const ShopPage = ({
  _fetchEditorsPicks,
  editorsPicks,
  location,
}: {
  _fetchEditorsPicks: Function,
  editorsPicks: Array<Object>,
  location: Object,
}) => {
  useEffect(() => {
    _fetchEditorsPicks();
  }, []);

  return (
    <Layout
      SEOProps={{
        title: SITE_METADATA.shop.title,
        description: SITE_METADATA.shop.description,
      }}
    >
      <header className={styles.header}>
        <h2 className={styles.headerTitle}>使えるお店</h2>
      </header>
      <Breadcrumb location={location} backgroundColor="#fafafa" />
      <WhereToShop
        appUrlsSp={{
          appStoreUrl: DYNAMICK_LINK.SHOP_SP,
          playStoreUrl: DYNAMICK_LINK.SHOP_SP,
        }}
        qrUrl={DYNAMICK_LINK.SHOP_QR}
        limit={16}
        noSeeMore
        className={styles.whereToShop}
        title="人気のお店"
        style={{ paddingTop: '0px' }}
      />
      <ShopWithPaidy />
      <EditorPicks
        location={location}
        items={editorsPicks}
        style={{ backgroundColor: '#fafafa' }}
        className={styles.editorPicksWrapper}
      />
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  editorsPicks: state.editorsPick.data,
  promotion: state.promotion.data,
});

const mapDispatchToProps = (dispatch: Function) => ({
  _fetchEditorsPicks: () => dispatch(fetchEditorsPicks()),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(ShopPage);
