import React from 'react';
import { Link } from 'gatsby';
import BoxIndex from '../BoxIndex/BoxIndex';
import image from '../../images/shop-paidycard.png';
import imageSp from '../../images/shop-paidycard-sp.png';
import styles from './ShopWithPaidy.module.scss';

const ShopWithPaidy = () => (
  <>
    <BoxIndex
      anchorId="shop_with_paidy"
      title={'ペイディカード'}
      style={{
        paddingBottom: 0,
        background: '#f0f0f0',
      }}
      sectionStyle={{ maxWidth: 1128 }}
      wrapperClassName={styles.pc}
    >
      <div className={styles.content}>
        <img
          src={image}
          alt="shopping on mobile"
          loading="lazy"
          className={styles.image}
        />
        <div className={styles.section}>
          <h3 className={styles.title}>
            ペイディカードを発行すると
            <br />
            Visaマークのお店ならどこでも使える。
          </h3>
          <p className={styles.text}>
            Visaマークのあるネットショップや交通系ICカード、スマホ決済、アプリストアのお支払い方法にペイディを追加できます。お買い物はもちろん、チャージや課金もペイディで。
            さらに、リアルカードを発行すると、Visaマークのある街のお店でもペイディが利用可能に。
          </p>
          <Link to="/paidycard/" className="btn gradient">
            ペイディカードの詳細を見る
          </Link>
          {/* <AppDownloadQR QRImg={qrImage} isGray={false} smallButtons /> */}
        </div>
      </div>
    </BoxIndex>
    <BoxIndex
      anchorId="shop_with_paidy"
      title={`ペイディカード`}
      style={{
        paddingBottom: 12,
        background: '#f0f0f0',
      }}
      sectionStyle={{ maxWidth: '100%' }}
      wrapperClassName={styles.sp}
    >
      <div className={styles.content}>
        <img
          src={imageSp}
          alt="shopping on mobile"
          loading="lazy"
          className={styles.image}
        />
        <div className={styles.section}>
          {/* <div> */}
          <h3 className={styles.title}>
            ペイディカードを発行するとVisaマークのお店ならどこでも使える。
          </h3>
          <p className={styles.text}>
            Visaマークのあるネットショップや交通系ICカード、スマホ決済、アプリストアのお支払い方法にペイディを追加できます。お買い物はもちろん、チャージや課金もペイディで。
            さらに、リアルカードを発行すると、Visaマークのある街のお店でもペイディが利用可能に。
          </p>
          <Link to="/paidycard/" className="btn gradient">
            ペイディカードの詳細を見る
          </Link>
          {/* <AppDownload isGray={false} wrapperStyle={styles.appDownload} /> */}
        </div>
      </div>
    </BoxIndex>
  </>
);

export default ShopWithPaidy;
